import { defineNuxtPlugin } from '#app'
import { useRoute } from 'vue-router'
import { watch } from 'vue'
import {useContractsStore} from "~/use/contracts/store";
import {usePropertiesStore} from "~/use/properties/store";
import {useCustomersStore} from "~/use/customers/store";
import {useEnvironmentalAgreementsStore} from "~/use/environmentalAgreements/store";
import {useTimberStore} from "~/use/timber/store";
import {useAllmaJobsStore} from "~/use/allmaJobs/store";

/**
 * Plugin that watches the current route and tidies up stores accordingly.
 * Prevents stale data and confusing redirections.
 */
export default defineNuxtPlugin((nuxtApp) => {
    const route = useRoute()
    const { currentContract, clearCurrentContract } = useContractsStore()
    const { currentCustomer, clearCurrentCustomer } = useCustomersStore()
    const { currentProperty, clearCurrentProperty } = usePropertiesStore()
    const { currentEnvironmentalAgreement, clearCurrentEnvironmentalAgreement } = useEnvironmentalAgreementsStore()
    const { currentTimber, clearCurrentTimber } = useTimberStore()
    const { clearAllmaJobs } = useAllmaJobsStore()

    watch(
        () => route.fullPath,
        (newPath) => {
            const currentRoute = localStorage.getItem('sbl.currentRoute')
            if (route.path !== currentRoute && !(route.path?.includes('loading') || route.path?.includes('login'))) {
                localStorage.setItem('sbl.currentRoute', route.path)
            }

            if ((!route.params.contractId && currentTimber.value?.Id) || (route.params.contractId && (route.params.contractId !== currentTimber.value?.Id))) {
                clearCurrentTimber()
            }

            if (!route.params.contractId && currentContract.value?.Id) {
                clearCurrentContract()
                clearAllmaJobs()
            }

            if (!route.params.customerId && currentCustomer.value?.Id) {
                clearCurrentCustomer()
            }

            if (!route.params.propertyId && currentProperty.value?.Id) {
                clearCurrentProperty()
                clearAllmaJobs()
            }

            if (!route.params.agreementId && currentEnvironmentalAgreement.value?.Id) {
                clearCurrentEnvironmentalAgreement()
            }
        }
    )
})
